/* eslint-disable react/require-default-props */
import PropTypes from "prop-types";

export default function HomeIcon({ active = false }) {

	if (active) {

		return (
			<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M13.2939 19C13.2939 17.8954 14.1894 17 15.2939 17H17.2939C18.3985 17 19.2939 17.8954 19.2939 19V25H13.2939V19Z" fill="#80BDBA" />
				<path d="M7.501 15.9999H9.2939V22.9999C9.2939 23.5304 9.50461 24.0391 9.87968 24.4141C10.2548 24.7892 10.7635 24.9999 11.2939 24.9999H13.2939V18.9999C13.2939 17.8954 14.1893 16.9999 15.2939 16.9999H17.2939C18.3985 16.9999 19.2939 17.8954 19.2939 18.9999V24.9999H21.2939C21.8243 24.9999 22.333 24.7892 22.7081 24.4141C23.0832 24.0391 23.2939 23.5304 23.2939 22.9999V15.9999H25.0868C25.5322 15.9999 25.7553 15.4614 25.4403 15.1464L16.6474 6.35348C16.4522 6.15822 16.1356 6.15822 15.9403 6.35348L7.14745 15.1464C6.83247 15.4614 7.05555 15.9999 7.501 15.9999Z" fill="#1B8884" />
			</svg>
		);

	}

	return (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M25.0868 15.9999H23.2939V22.9999C23.2939 23.5304 23.0832 24.0391 22.7081 24.4141C22.333 24.7892 21.8243 24.9999 21.2939 24.9999H11.2939C10.7635 24.9999 10.2548 24.7892 9.87968 24.4141C9.50461 24.0391 9.2939 23.5304 9.2939 22.9999V15.9999H7.501C7.05555 15.9999 6.83247 15.4614 7.14745 15.1464L15.9403 6.35348C16.1356 6.15822 16.4522 6.15822 16.6474 6.35348L25.4403 15.1464C25.7553 15.4614 25.5322 15.9999 25.0868 15.9999ZM10.2939 15.9999C10.2939 15.4476 9.84618 14.9999 9.2939 14.9999H8.70811L16.2939 7.41414L23.8797 14.9999H23.2939C22.7416 14.9999 22.2939 15.4476 22.2939 15.9999V22.9999C22.2939 23.2651 22.1885 23.5195 22.001 23.707C21.8135 23.8946 21.5591 23.9999 21.2939 23.9999H20.2939V18.9999C20.2939 17.3431 18.9508 15.9999 17.2939 15.9999H15.2939C13.637 15.9999 12.2939 17.3431 12.2939 18.9999V23.9999H11.2939C11.0287 23.9999 10.7743 23.8946 10.5868 23.707C10.3993 23.5195 10.2939 23.2651 10.2939 22.9999V15.9999ZM13.2939 23.9999H19.2939V18.9999C19.2939 17.8954 18.3985 16.9999 17.2939 16.9999H15.2939C14.1893 16.9999 13.2939 17.8954 13.2939 18.9999V23.9999Z" fill="#919EAB" />
		</svg>
	);

}
HomeIcon.propTypes = {
	active: PropTypes.bool,
};
