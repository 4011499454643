/* eslint-disable react/require-default-props */
import PropTypes from "prop-types";

export default function IconMyOrder({ margin, padding, active }) {

	return (
		<svg
			width="26"
			height="26"
			viewBox="0 0 26 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				margin,
				padding,
			}}
		>
			<path d="M9 5H7C6.46957 5 5.96086 5.21071 5.58579 5.58579C5.21071 5.96086 5 6.46957 5 7V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V7C19 6.46957 18.7893 5.96086 18.4142 5.58579C18.0391 5.21071 17.5304 5 17 5H15" stroke={active ? "#d7244e" : "#454f5b"} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M13 3H11C9.89543 3 9 3.89543 9 5C9 6.10457 9.89543 7 11 7H13C14.1046 7 15 6.10457 15 5C15 3.89543 14.1046 3 13 3Z" stroke={active ? "#d7244e" : "#454f5b"} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M9 12H9.01" stroke={active ? "#D7244E" : "#454F5B"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M13 12H15" stroke={active ? "#D7244E" : "#454F5B"} strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M9 16H9.01" stroke={active ? "#D7244E" : "#454F5B"} strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M13 16H15" stroke={active ? "#D7244E" : "#454F5B"} strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);

}

IconMyOrder.propTypes = {
	margin: PropTypes.string,
	padding: PropTypes.string,
	active: PropTypes.bool,
};

IconMyOrder.defaultProps = {
	margin: "0 0 0 0",
	padding: "0 0 0 0",
	active: false,
};
