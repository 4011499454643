/* eslint-disable react/require-default-props */
import PropTypes from "prop-types";

export default function IconXClose({ isMobile }) {

	if (isMobile) {

		return (
			<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect width="12" height="12" rx="6" fill="black" fillOpacity="0.75" />
				<path d="M9 3L3 9M3 3L9 9" stroke="white" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" />
			</svg>

		);

	}

	return (
		<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M13 1L1 13M1 1L13 13" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		</svg>

	);

}

IconXClose.propTypes = {
	isMobile: PropTypes.bool,
};

IconXClose.defaultProps = {
	isMobile: false,
};
