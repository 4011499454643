/* eslint-disable react/require-default-props */
import PropTypes from "prop-types";

export default function CategoryIcon({ active = false }) {

	if (active) {

		return (
			<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M10.9492 7V10.3333C10.9492 10.8856 11.3969 11.3333 11.9492 11.3333H13.3867C13.939 11.3333 14.3867 10.8856 14.3867 10.3333V9.16188C14.8432 9.11496 15.1992 8.72927 15.1992 8.26042V7C15.1992 6.44772 14.7515 6 14.1992 6H11.9492C11.3969 6 10.9492 6.44772 10.9492 7Z" fill="#1B8884" />
				<path d="M14.9163 14.5833L15.4163 14.0833V11.3333C15.4163 10.781 14.9686 10.3333 14.4163 10.3333H11.083C10.5307 10.3333 10.083 10.781 10.083 11.3333V14.0833L10.583 14.5833H14.9163Z" fill="#A4CFCE" />
				<path d="M9.21094 14.0833C8.65865 14.0833 8.21094 14.531 8.21094 15.0833V24.9999C8.21094 25.5522 8.65865 25.9999 9.21094 25.9999H15.889C16.2659 25.9999 16.594 25.7915 16.7645 25.4837H18.1255V16.2499H16.889V15.0833C16.889 14.531 16.4413 14.0833 15.889 14.0833H9.21094Z" fill="#1B8884" />
				<path d="M23.666 18.6066H15.916C15.0876 18.6066 14.416 19.2781 14.416 20.1066V25C14.416 25.8284 15.0876 26.5 15.916 26.5H23.666C24.4944 26.5 25.166 25.8284 25.166 25V20.1066C25.166 19.2781 24.4944 18.6066 23.666 18.6066Z" fill="#49A09D" />
				<path d="M23.666 19.6066H15.916C15.0876 19.6066 14.416 18.935 14.416 18.1066V16.8566C14.416 16.0281 15.0876 15.3566 15.916 15.3566H23.666C24.4944 15.3566 25.166 16.0281 25.166 16.8566V18.1066C25.166 18.935 24.4944 19.6066 23.666 19.6066Z" fill="#A4CFCE" />
			</svg>

		);

	}

	return (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M10.9497 7C10.9497 6.44772 11.3974 6 11.9497 6H14.1997C14.752 6 15.1997 6.44772 15.1997 7V8.26042C15.1997 8.72927 14.8437 9.11496 14.3872 9.16188V10.3333H14.4163C14.9686 10.3333 15.4163 10.781 15.4163 11.3333V13.5833H15.889C16.7175 13.5833 17.389 14.2549 17.389 15.0833V15.3566H23.6665C24.4949 15.3566 25.1665 16.0281 25.1665 16.8566V18.1066C25.1665 18.4907 25.0221 18.8412 24.7846 19.1066C25.0221 19.3719 25.1665 19.7224 25.1665 20.1066V25C25.1665 25.8284 24.4949 26.5 23.6665 26.5H15.9165C15.9125 26.5 15.9085 26.4999 15.9045 26.4999C15.8993 26.5 15.8942 26.5 15.889 26.5H9.21094C8.38251 26.5 7.71094 25.8284 7.71094 25V15.0833C7.71094 14.2549 8.38251 13.5833 9.21094 13.5833H10.083V11.3333C10.083 10.8262 10.4604 10.4073 10.9497 10.3421V7ZM17.3037 25.5C17.3056 25.4946 17.3075 25.4891 17.3093 25.4837H16.016C15.9799 25.4932 15.9422 25.4987 15.9033 25.4998C15.6333 25.4928 15.4165 25.2717 15.4165 25V20.1066C15.4165 19.8304 15.6404 19.6066 15.9165 19.6066H23.6665C23.9426 19.6066 24.1665 19.8304 24.1665 20.1066V25C24.1665 25.2761 23.9426 25.5 23.6665 25.5H17.3037ZM14.5019 25.5H9.21094C8.9348 25.5 8.71094 25.2761 8.71094 25V15.0833C8.71094 14.8072 8.9348 14.5833 9.21094 14.5833H15.889C16.1652 14.5833 16.389 14.8072 16.389 15.0833V15.3566H15.9165C15.0881 15.3566 14.4165 16.0281 14.4165 16.8566V18.1066C14.4165 18.4907 14.5609 18.8412 14.7985 19.1066C14.5609 19.3719 14.4165 19.7224 14.4165 20.1066V25C14.4165 25.1753 14.4466 25.3436 14.5019 25.5ZM11.083 13.5833H14.4163V11.3333H11.083V13.5833ZM13.3872 9.07292V10.3333H11.9497V7L14.1997 7V8.17146C13.7432 8.21837 13.3872 8.60406 13.3872 9.07292ZM23.6665 18.6066H15.9165C15.6404 18.6066 15.4165 18.3827 15.4165 18.1066V16.8566C15.4165 16.5804 15.6404 16.3566 15.9165 16.3566H23.6665C23.9426 16.3566 24.1665 16.5804 24.1665 16.8566V18.1066C24.1665 18.3827 23.9426 18.6066 23.6665 18.6066Z" fill="#919EAB" />
		</svg>

	);

}
CategoryIcon.propTypes = {
	active: PropTypes.bool,
};
