/* eslint-disable react/require-default-props */

export default function UserIcon() {

	return (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M16.5 7C14.0147 7 12 9.01472 12 11.5C12 13.9853 14.0147 16 16.5 16C18.9853 16 21 13.9853 21 11.5C21 9.01472 18.9853 7 16.5 7ZM13 11.5C13 9.567 14.567 8 16.5 8C18.433 8 20 9.567 20 11.5C20 13.433 18.433 15 16.5 15C14.567 15 13 13.433 13 11.5Z" fill="#919EAB" />
			<path d="M12.0251 21.0251C12.6815 20.3687 13.5717 20 14.5 20H18.5C19.4283 20 20.3185 20.3687 20.9749 21.0251C21.6313 21.6815 22 22.5717 22 23.5V25.5C22 25.7761 22.2239 26 22.5 26C22.7761 26 23 25.7761 23 25.5V23.5C23 22.3065 22.5259 21.1619 21.682 20.318C20.8381 19.4741 19.6935 19 18.5 19H14.5C13.3065 19 12.1619 19.4741 11.318 20.318C10.4741 21.1619 10 22.3065 10 23.5V25.5C10 25.7761 10.2239 26 10.5 26C10.7761 26 11 25.7761 11 25.5V23.5C11 22.5717 11.3687 21.6815 12.0251 21.0251Z" fill="#919EAB" />
		</svg>

	);

}
