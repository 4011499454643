/* eslint-disable react/require-default-props */
import PropTypes from "prop-types";

export default function IconChat({ isMobile }) {

	if (isMobile) {

		return (
			<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M10 18C13.3333 21.3333 18.6667 21.3333 22 18M23.736 23.0558C23.736 23.0558 23.8387 22.9825 24.0027 22.8571C26.46 20.9571 28 18.2038 28 15.1385C28 9.4238 22.6267 4.78647 16.0027 4.78647C9.376 4.78647 4 9.4238 4 15.1385C4 20.8558 9.37333 25.3331 16 25.3331C16.5653 25.3331 17.4933 25.2958 18.784 25.2211C20.4667 26.3145 22.9227 27.2118 25.072 27.2118C25.7373 27.2118 26.0507 26.6651 25.624 26.1078C24.976 25.3131 24.0827 24.0411 23.736 23.0558Z" stroke="#DB264D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
				<rect x="9" y="17" width="14" height="5" fill="white" />
				<path fillRule="evenodd" clipRule="evenodd" d="M13.4133 12.3733C13.4133 11.1051 14.6741 10.0533 16 10.0533C17.3259 10.0533 18.5867 11.1051 18.5867 12.3733C18.5867 13.5456 17.9925 14.0949 17.1936 14.8043L17.1605 14.8331C16.368 15.536 15.3867 16.4053 15.3867 18.1333C15.3867 18.2139 15.4025 18.2936 15.4333 18.368C15.4642 18.4425 15.5093 18.5101 15.5663 18.567C15.6233 18.624 15.6909 18.6692 15.7653 18.7C15.8397 18.7308 15.9194 18.7467 16 18.7467C16.0805 18.7467 16.1603 18.7308 16.2347 18.7C16.3091 18.6692 16.3767 18.624 16.4337 18.567C16.4906 18.5101 16.5358 18.4425 16.5666 18.368C16.5975 18.2936 16.6133 18.2139 16.6133 18.1333C16.6133 16.976 17.2043 16.4331 18.0075 15.7227L18.0288 15.7013C18.8267 14.9952 19.8133 14.1205 19.8133 12.3733C19.8133 10.2699 17.8336 8.82666 16 8.82666C14.1664 8.82666 12.1867 10.2699 12.1867 12.3733C12.1867 12.4539 12.2025 12.5336 12.2333 12.608C12.2642 12.6825 12.3093 12.7501 12.3663 12.807C12.4233 12.864 12.4909 12.9091 12.5653 12.94C12.6397 12.9708 12.7195 12.9867 12.8 12.9867C12.8805 12.9867 12.9603 12.9708 13.0347 12.94C13.1091 12.9091 13.1767 12.864 13.2337 12.807C13.2906 12.7501 13.3358 12.6825 13.3666 12.608C13.3975 12.5336 13.4133 12.4539 13.4133 12.3733ZM16 22.2485C16.2475 22.2485 16.4849 22.1502 16.66 21.9752C16.835 21.8001 16.9333 21.5627 16.9333 21.3152C16.9333 21.0677 16.835 20.8303 16.66 20.6552C16.4849 20.4802 16.2475 20.3819 16 20.3819C15.7525 20.3819 15.5151 20.4802 15.34 20.6552C15.165 20.8303 15.0667 21.0677 15.0667 21.3152C15.0667 21.5627 15.165 21.8001 15.34 21.9752C15.5151 22.1502 15.7525 22.2485 16 22.2485Z" fill="#DB264D" />
			</svg>
		);

	}

	return (
		<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M15 27C20 32 28 32 33 27M35.604 34.5837C35.604 34.5837 35.758 34.4737 36.004 34.2857C39.69 31.4357 42 27.3057 42 22.7077C42 14.1357 33.94 7.17969 24.004 7.17969C14.064 7.17969 6 14.1357 6 22.7077C6 31.2837 14.06 37.9997 24 37.9997C24.848 37.9997 26.24 37.9437 28.176 37.8317C30.7 39.4717 34.384 40.8177 37.608 40.8177C38.606 40.8177 39.076 39.9977 38.436 39.1617C37.464 37.9697 36.124 36.0617 35.604 34.5837Z" stroke="#DB264D" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
			<rect x="13" y="24" width="22" height="9" fill="white" />
			<path d="M23.3279 26.9474C22.9835 26.9474 22.6929 26.8281 22.4564 26.5895C22.2193 26.3509 22.1151 26.0702 22.1438 25.7474C22.1868 25.1579 22.3304 24.6456 22.5744 24.2105C22.8184 23.7754 23.2059 23.3263 23.737 22.8632C24.3255 22.3579 24.7739 21.9192 25.0822 21.5469C25.3911 21.1753 25.5456 20.7509 25.5456 20.2737C25.5456 19.6982 25.3483 19.2211 24.9539 18.8421C24.5589 18.4632 24.0097 18.2737 23.3064 18.2737C22.8471 18.2737 22.4561 18.3683 22.1335 18.5575C21.8102 18.7472 21.5481 18.9825 21.3472 19.2632C21.1462 19.5439 20.8988 19.7333 20.6048 19.8316C20.3103 19.9298 20.0267 19.9228 19.7539 19.8105C19.4238 19.6702 19.1979 19.4386 19.0762 19.1158C18.9539 18.793 18.9788 18.4912 19.1511 18.2105C19.5673 17.5789 20.1271 17.0526 20.8304 16.6316C21.5338 16.2105 22.3591 16 23.3064 16C24.8135 16 25.9727 16.4107 26.784 17.232C27.5947 18.0528 28 19.0386 28 20.1895C28 20.8912 27.8458 21.4914 27.5375 21.9899C27.2286 22.4879 26.7441 23.0526 26.0838 23.6842C25.5958 24.1333 25.2513 24.4982 25.0504 24.7789C24.8494 25.0596 24.7131 25.3825 24.6413 25.7474C24.5839 26.0982 24.4332 26.386 24.1892 26.6105C23.9451 26.8351 23.6581 26.9474 23.3279 26.9474ZM23.3064 32C22.8328 32 22.4274 31.8352 22.0904 31.5057C21.7528 31.1756 21.584 30.7789 21.584 30.3158C21.584 29.8526 21.7528 29.456 22.0904 29.1259C22.4274 28.7964 22.8328 28.6316 23.3064 28.6316C23.7801 28.6316 24.1857 28.7964 24.5233 29.1259C24.8603 29.456 25.0288 29.8526 25.0288 30.3158C25.0288 30.7789 24.8603 31.1756 24.5233 31.5057C24.1857 31.8352 23.7801 32 23.3064 32Z" fill="#DB264D" />
		</svg>
	);

}

IconChat.propTypes = {
	isMobile: PropTypes.bool,
};

IconChat.defaultProps = {
	isMobile: false,
};
