/* eslint-disable react/require-default-props */
import PropTypes from "prop-types";

export default function BrandIcon({ active = false }) {

	if (active) {

		return (
			<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M14.1716 7C14.702 7 15.2107 7.21071 15.5858 7.58579L24 16C24.246 16.275 24.382 16.631 24.382 17C24.382 17.369 24.246 17.725 24 18L18 24C17.725 24.246 17.369 24.382 17 24.382C16.631 24.382 16.275 24.246 16 24L7.58579 15.5858C7.21071 15.2107 7 14.702 7 14.1716V11C7 9.93913 7.42143 8.92172 8.17157 8.17157C8.92172 7.42143 9.93913 7 11 7H14.1716Z" fill="#1B8884" />
				<path d="M13 15C14.1046 15 15 14.1046 15 13C15 11.8954 14.1046 11 13 11C11.8954 11 11 11.8954 11 13C11 14.1046 11.8954 15 13 15Z" fill="#A4CFCE" />
			</svg>
		);

	}

	return (
		<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M11.167 7.5C10.2387 7.5 9.3485 7.86875 8.69212 8.52513C8.03574 9.1815 7.66699 10.0717 7.66699 11V14.1716C7.66699 14.5694 7.82503 14.9509 8.10633 15.2322L16.5097 23.6356C16.6917 23.7944 16.9252 23.882 17.167 23.882C17.4088 23.882 17.6423 23.7944 17.8243 23.6356L23.8026 17.6573C23.9614 17.4753 24.049 17.2418 24.049 17C24.049 16.7582 23.9614 16.5247 23.8026 16.3427L15.3992 7.93934C15.1179 7.65804 14.7364 7.5 14.3386 7.5H11.167ZM7.98501 7.81802C8.82893 6.97411 9.97352 6.5 11.167 6.5H14.3386C15.0016 6.5 15.6375 6.76339 16.1063 7.23223L24.5205 15.6464C24.5271 15.653 24.5335 15.6598 24.5397 15.6667C24.8676 16.0333 25.049 16.508 25.049 17C25.049 17.492 24.8676 17.9667 24.5397 18.3333C24.5335 18.3402 24.5271 18.347 24.5205 18.3536L18.5205 24.3536C18.5172 24.3569 18.5139 24.3601 18.5105 24.3634C18.5071 24.3665 18.5037 24.3696 18.5003 24.3727C18.1336 24.7006 17.6589 24.882 17.167 24.882C16.675 24.882 16.2003 24.7006 15.8337 24.3727C15.8267 24.3665 15.82 24.3601 15.8134 24.3536L7.39922 15.9393C6.93038 15.4705 6.66699 14.8346 6.66699 14.1716V11C6.66699 9.80653 7.1411 8.66193 7.98501 7.81802Z" fill="#919EAB" />
			<path fillRule="evenodd" clipRule="evenodd" d="M13.167 11.5C12.3386 11.5 11.667 12.1716 11.667 13C11.667 13.8284 12.3386 14.5 13.167 14.5C13.9954 14.5 14.667 13.8284 14.667 13C14.667 12.1716 13.9954 11.5 13.167 11.5ZM10.667 13C10.667 11.6193 11.7863 10.5 13.167 10.5C14.5477 10.5 15.667 11.6193 15.667 13C15.667 14.3807 14.5477 15.5 13.167 15.5C11.7863 15.5 10.667 14.3807 10.667 13Z" fill="#919EAB" />
		</svg>
	);

}
BrandIcon.propTypes = {
	active: PropTypes.bool,
};
